<template>
  <div>
    <SidebarLeft v-if="showMapLeft" class="sidebar-left-show left-map">
      <MapObjects :coordsItems="coordsItems" :objects="objects" :zoom="zoom" @close-map="showMapLeft = false" />
    </SidebarLeft>

    <SidebarLeft v-else :class="{'sidebar-left-show': $store.state.sidebars.leftSidebar}">
      <FilterObjectsForm v-if="isLeftSidebarVisible" @on-update-table="updateData" />
    </SidebarLeft>

    <TableContainer
      v-show="!showMapLeft"
      ref="table-container"
      :columns="tableData"
      :counter-filter="searchCount"
      :is-archive="$can('edit', 'base-object-read-archive')"
      :is-archiving="$can('edit', 'base-object-trash')"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-remove-from-archive="$can('edit', 'base-object-delete')"
      :is-show-title-button="!showMapRight"
      :rows="objects"
      :show-add-button="$can('edit', 'base-object-create')"
      :sorting-fields="sortingFields"
      :title="$t('Objects') || ''"
      name="objects"
      show-add-button
      @show-right-sidebar="showRightSidebar"
      @on-selected="selectItem"
      @delete-item="deleteItem"
      @archive-items="archiveItems"
      @update-data="updateData"
      @return-from-archive="returnFromArchiveProducts"
    >
      <template slot="head-buttons">
        <zem-link
          v-if="selectedObjects.length > 0"
          v-tippy="{content: $t('Open on the map')}"
          class="mt-0 zem-link-mobile"
          @click="
            showMapRight = true
            $store.commit('sidebars/changeLeftSidebar', false)
          "
        >
          <map-icon size="16" />
          <span v-if="!showMapRight" class="tw-hidden md:tw-block tw-ml-1.5">{{ $t('Open on the map') }}</span>
        </zem-link>
      </template>
    </TableContainer>

    <SidebarRight
      v-if="showMapRight"
      class="sidebar-right--show tw-w-[1000px] max-md:tw-w-full"
      style="overflow: unset"
    >
      <MapObjects :coordsItems="coordsItems" :objects="objects" :zoom="zoom" @close-map="showMapRight = false" />
      <!--      <LeafletMap ref="map" :coords-items="coordsItems" :center="center"/>-->
    </SidebarRight>

    <SidebarRight v-else-if="$store.state.sidebars.rightSidebar" class="sidebar-right--show">
      <ObjectDetails
        ref="objectDetails"
        :loading-new-element="loadingNewElement"
        :new-element="newElement"
        @open-map="showMapLeft = true"
        @close-map="showMapLeft = false"
        @on-create="createElement"
        @on-update="updateElement"
      />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import TableContainer from '@/components/TableContainer'
import SidebarLeft from '@/components/SidebarLeft'
import FilterObjectsForm from '@/components/FilterObjectsForm.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import {MapIcon} from 'vue-feather-icons'
import ObjectDetails from '@/components/ObjectDetails.vue'
import LeafletMap from '@/components/LeafletCustomIcon.vue'
import MapObjects from '@/components/MapObjects.vue'
import ObjectsService from '@/services/objects.service'
import RoleDetails from '@/components/RoleDetails.vue'
import {errorHandler} from '@/assets/scripts/scripts'
import FilterOrdersForm from '@/components/Orders/FilterOrdersForm.vue'

export default {
  components: {
    FilterOrdersForm,
    RoleDetails,
    MapObjects,
    LeafletMap,
    ObjectDetails,
    ZemButton,
    ZemLink,
    FilterObjectsForm,
    TableContainer,
    SidebarLeft,
    SidebarRight,
    MapIcon,
  },
  data() {
    return {
      isLeftSidebarVisible: true,
      newElement: false,
      loadingNewElement: false,
      showMapRight: false,
      showMapLeft: false,
      currentItem: null,
      coordsItems: [],
      center: [],
      zoom: 2,
      sortingFields: ['id', 'name', 'sku', 'region_id', 'highway_id', 'distance_from_mkad', 'square'],
      tableData: [
        {
          label: 'ID',
          field: 'number',
          sortField: 'id',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Название',
          field: 'name',
          sortField: 'name',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Артикул',
          field: 'sku',
          sortField: 'sku',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Регион',
          field: 'region.data.name',
          sortField: 'region_id',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Шоссе',
          field: 'highway.data.name',
          sortField: 'highway_id',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Адрес',
          field: 'address',
          sortField: 'address',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Координаты',
          field: 'coordinates',
          sortField: 'coordinates',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Удаленность, км.',
          field: 'distance_from_mkad',
          sortField: 'distance_from_mkad',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Площадь объекта, кв.м.',
          field: 'square',
          sortField: 'square',
          showInTable: false,
          sort: 0,
        },
        {
          label: 'Номер',
          field: 'cadastral_number',
          sortField: 'cadastral_number',
          showInTable: true,
          sort: 0,
        },
      ],
    }
  },

  computed: {
    searchCount() {
      return this.$store.state.objects.searchCount
    },
    objects() {
      return this.$store.state.objects.elements
    },
    selectedObjects() {
      return this.$store.state.objects.selectedItemsForDelete
    },
  },

  watch: {
    selectedObjects() {
      const {elements, selectedItemsForDelete} = this.$store.state.objects
      const coords = elements.filter(el => selectedItemsForDelete.includes(el.id))

      this.zoom = 15
      this.coordsItems = coords.map(el => el.coordinates.split(', '))

      this.center = [this.coordsItems[0]]
    },
  },

  mounted() {
    const {id} = this.$route.params
    if (!this.$store.state.objects.isArchive) this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
    this.$store.commit('sidebars/changeLeftSidebar', false)
    this.updateData()
  },
  methods: {
    updateData() {
      ObjectsService.getObjects()
    },
    async selectItem(data) {
      if (data.column.field !== 'checkbox') {
        await this.$store.commit('sidebars/changeRightSidebar', true)
      } else {
        await this.$router.push({name: 'objects'})
      }

      this.currentItem = data.row

      this.newElement = false

      this.$refs.objectDetails.getData(data.row.id)
      await this.$router.push({name: 'objects-id', params: {id: data.row.id}})
    },
    createElement() {
      this.loadingNewElement = true
      const data = Object.assign({}, this.$refs.objectDetails.currentItem)
      data.region ? (data['region_id'] = data.region.value) : delete data['region_id']
      data.highway ? (data['highway_id'] = data.highway.value) : delete data['highway_id']
      ObjectsService.createObject(data)
        .then(() => {
          this.loadingNewElement = false
          this.newElement = false
          this.updateData()
          this.$store.commit('sidebars/changeRightSidebar', false)
        })
        .catch(e => {
          this.loadingNewElement = false
          errorHandler(e)
        })
    },
    updateElement() {
      this.loadingNewElement = true
      const data = this.$refs.objectDetails.currentItem
      data.region ? (data['region_id'] = data.region.value) : delete data['region_id']
      data.highway ? (data['highway_id'] = data.highway.value) : delete data['highway_id']
      ObjectsService.updateObject(this.$refs.objectDetails.currentItem.id, data)
        .then(() => {
          this.loadingNewElement = false
          this.newElement = false
          this.$refs.objectDetails.isChangeData = false
          this.updateData()
        })
        .catch(e => {
          this.loadingNewElement = false
          errorHandler(e)
        })
    },
    async showRightSidebar() {
      this.newElement = true
      await this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.objects.isArchive)
      this.$store.commit('objects/itemsForDelete', [])
      this.$refs.objectDetails.currentItem = {
        name: '',
      }
      this.currentItem = {}
      if (this.$route.name !== 'objects') await this.$router.push({name: 'objects'})
    },
    async returnFromArchiveProducts() {
      await ObjectsService.archiveItems()
      await this.updateData()
    },
    archiveItems() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.state.objects.selectedItemsForDelete.forEach(async (item, index, arr) => {
        if (index + 1 !== arr.length) {
          await ObjectsService.deleteItems()
        } else {
          await ObjectsService.deleteItems().then(r => {
            this.$toast.success(r.data['message'])
            this.updateData()
          })
        }
      })
    },
    deleteItem() {
      this.$refs['table-container'].loadingDelete = true
      ObjectsService.deleteItems(true)
        .then(r => {
          this.$toast.success(r.data.message)
          this.$refs['table-container'].loadingDelete = false
          this.$refs['table-container'].showDeleteModal = false
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('objects/itemsForDelete', [])
          this.updateData()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
  },
  beforeDestroy() {
    this.$store.commit('objects/getAllObjects', {
      data: [],
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__container {
    height: 100vh;
  }
}
</style>
