<template>
  <div class="tw-w-full tw-h-[calc(100%-45px)] md:tw-h-full">
    <div class="sidebar-right-container position-relative tw-overflow-y-auto tw-h-full tw-w-full">
      <template v-if="currentItem">
        <zem-card>
          <div>
            <template>
              <zem-card-title v-if="newElement" class="mb-0">Создание объекта</zem-card-title>
              <template v-else>
                <zem-card-title>Объект: {{ currentItem['name'] }}</zem-card-title>
                <div class="block-id">
                  <span class="block-id__label"></span>
                  ID {{ currentItem['number'] }}
                </div>
                <div v-if="!closeAcc([3])" class="block-id">
                  <span class="block-id__label second"></span>
                  ID {{ currentItem['id'] }}
                </div>
              </template>
            </template>
          </div>
          <div class="tw-flex tw-items-center tw-gap-x-3">
            <template v-if="!isChangeData">
              <!--              <img :src="require('@/assets/icons/edit.svg')" alt="" class="block-id__edit" @click="edit = true" />-->
              <!--              <img :src="require('@/assets/icons/close.svg')" alt="" class="" @click="closeRightSidebar" />-->
              <XIcon class="text-primary tw-cursor-pointer" size="18" @click="closeRightSidebar" />
            </template>
            <template v-else>
              <zem-link
                v-if="$can('edit', 'base-object-update') && isChangeData"
                class="tw-hidden md:tw-block"
                @click="cancelData"
                >Отменить
              </zem-link>
              <zem-button
                v-if="$can('edit', 'base-object-update') && isChangeData"
                :loading="loadingNewElement"
                class="mobile-d-none"
                @click="$emit(!edit ? 'on-create' : 'on-update')"
              >
                Сохранить
              </zem-button>
              <img
                v-if="$can('edit', 'base-object-update') && isChangeData"
                :src="require('@/assets/icons/check_primary.svg')"
                alt=""
                class="tw-block md:tw-hidden tw-h-4"
                @click="$emit(!edit ? 'on-create' : 'on-update')"
              />
              <img
                :src="require('@/assets/icons/close.svg')"
                alt=""
                class="tw-block md:tw-hidden tw-h-5"
                @click="closeRightSidebar"
              />
            </template>
          </div>
        </zem-card>

        <zem-collapse is-opened-default title="Расположение">
          <template slot="header">
            <zem-link v-if="!newElement" class="mt-0 zem-link-mobile mr-3" @click="$emit('open-map')">
              <map-icon class="mr-2" size="16" />
              <span class="tw-hidden lg:tw-block">{{ $t('Open on the map') }}</span>
            </zem-link>
          </template>
          <div class="zem-collapse-table rows">
            <div class="zem-collapse-table__row" style="grid-template-columns: auto 1fr; gap: 5px 10px">
              <div class="zem-collapse-table__column required-field title mt-1">Название</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['name'] }}
              </div>
              <ZemInput
                v-else
                :value="currentItem['name']"
                class="mt-0"
                type="text"
                @input="
                  e => {
                    currentItem['name'] = e
                    isChangeData = true
                  }
                "
              />

              <div class="zem-collapse-table__column required-field title mt-1">Артикул</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['sku'] }}
              </div>
              <ZemInput
                v-else
                :disabled="$store.state.user.role !== 'admin'"
                :value="currentItem['sku']"
                class="mt-0"
                type="text"
                @input="
                  e => {
                    currentItem['sku'] = e
                    isChangeData = true
                  }
                "
              />

              <div class="zem-collapse-table__column required-field title mt-1">Регион</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['region'].title }}
              </div>
              <ZemDropdownList
                v-else
                v-model="currentItem['region']"
                :options="regions"
                class="mt-0"
                search
                @input="
                  e => {
                    currentItem['region'] = e
                    isChangeData = true
                  }
                "
              />

              <div class="zem-collapse-table__column title mt-1">Шоссе</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['highway'].title }}
              </div>
              <ZemDropdownList
                v-else
                v-model="currentItem['highway']"
                :options="highways"
                class="mt-0"
                search
                @input="
                  e => {
                    currentItem['highway'] = e
                    isChangeData = true
                  }
                "
              />

              <div class="zem-collapse-table__column title mt-1">Адрес</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['address'] }}
              </div>
              <ZemInput
                v-else
                :value="currentItem['address']"
                class="mt-0"
                type="text"
                @input="
                  e => {
                    currentItem['address'] = e
                    isChangeData = true
                  }
                "
              />

              <div class="zem-collapse-table__column title mt-1">Координаты</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['coordinates'] }}
              </div>
              <ZemInput
                v-else
                :value="currentItem['coordinates']"
                class="mt-0"
                type="text"
                @input="
                  e => {
                    currentItem['coordinates'] = e
                    isChangeData = true
                  }
                "
              />

              <div class="zem-collapse-table__column title mt-1">Удаленность, км.</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['distance_from_mkad'] }}
              </div>
              <ZemInput
                v-else
                :value="currentItem['distance_from_mkad']"
                class="mt-0"
                type="number"
                @input="
                  e => {
                    currentItem['distance_from_mkad'] = e
                    isChangeData = true
                  }
                "
              />

              <div class="zem-collapse-table__column title mt-1">Площадь объекта, кв.м.</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['square'] }}
              </div>
              <ZemInput
                v-else
                :value="currentItem['square']"
                class="mt-0"
                type="number"
                @input="
                  e => {
                    currentItem['square'] = e
                    isChangeData = true
                  }
                "
              />

              <div class="zem-collapse-table__column title mt-1">Кадастровый номер</div>
              <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1" style="white-space: unset">
                {{ currentItem['cadastral_number'] }}
              </div>
              <ZemInput
                v-else
                :value="currentItem['cadastral_number']"
                class="mt-0"
                type="text"
                @input="
                  e => {
                    currentItem['cadastral_number'] = e
                    isChangeData = true
                  }
                "
              />
            </div>
          </div>
        </zem-collapse>

        <zem-collapse title="Заказчики"></zem-collapse>

        <zem-collapse title="История заказов" v-if="currentItem">
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <div v-for="order in currentItem['orders'].data" :key="order.id">
              <div class="d-flex align-center">
                <span
                  :style="{
                    background: Object.hasOwn(order.status.data.params, 'color')
                      ? order.status.data.params.color
                      : '#ffffff',
                  }"
                  class="marker"
                ></span>
                <RouterLink
                  v-tippy="{content: 'Перейти в заказ'}"
                  :to="`/orders/${order.id}`"
                  class="tw-text-[#0DB2B2] tw-font-semibold tw-text-xs tw-leading-[1.2] tw-cursor-pointer hover:tw-underline"
                  target="_blank"
                  >{{ order.title }}
                </RouterLink>
              </div>
              <div class="zem-collapse-table rows tw-ml-6 tw-mt-2">
                <div class="zem-collapse-table__row tw-grid-cols-[1fr_2fr] tw-gap-[8px_10px]">
                  <div class="zem-collapse-table__column title">Бюджет, руб.</div>
                  <div class="zem-collapse-table__column text" style="white-space: unset">
                    {{ order.budget.currency.text }}
                  </div>
                  <div class="zem-collapse-table__column title">Этап</div>
                  <div class="zem-collapse-table__column text" style="white-space: unset">
                    {{ order.pipeline.data.name }} - {{ order.status.data.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </zem-collapse>
      </template>

      <div v-show="isLoadingData" class="sidebar-right-preloader">
        <ZemPreloader :color="'dark'" :size="20" />
      </div>
    </div>
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard'
import ZemCardTitle from '@/components/ui/ZemCardTitle'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ZemInput from '@/components/ui/ZemInput'
import ZemDropdownList from '@/components/ui/ZemDropdownList'
import ZemSelect from '@/components/ui/Select.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import {eventBus} from '@/main'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'
import {closeAcc} from '@/assets/scripts/scripts'
import {MapIcon, XIcon} from 'vue-feather-icons'
import ProfileService from '@/services/profile.service'
import OtherService from '@/services/other.service'
import ObjectsService from '@/services/objects.service'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'

export default {
  components: {
    ZemPreloader,
    ZemCheckbox,
    ZemRadioButton,
    ZemSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
    MapIcon,
    XIcon,
  },
  props: {
    newElement: {
      type: Boolean,
      default: false,
    },
    loadingNewElement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      isLoadingData: false,
      currentItem: null,
      highways: [],
      regions: [],
      isChangeData: false,
    }
  },
  mounted() {
    const {id} = this.$route.params

    this.edit = this.$can('edit', 'base-object-update')

    ProfileService.getRegionsList().then(r => {
      this.regions = r.data.data
    })
    OtherService.getHighways().then(r => {
      this.highways = r.data.data
    })
    if (id) {
      this.getData(id)
    }
  },
  methods: {
    closeAcc,
    closeRightSidebar() {
      this.edit = false
      this.newElement = false
      eventBus.$emit('new-state', false)
      this.$emit('close-map')
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$router.push({name: 'objects'})
    },
    cancelData() {
      this.getData(this.currentItem.id)
      this.isChangeData = false
    },
    getData(id) {
      this.isLoadingData = true
      this.isChangeData = false
      ObjectsService.getObject(id)
        .then(r => {
          const {
            number,
            region,
            highway,
            name,
            id,
            sku,
            address,
            coordinates,
            distance_from_mkad,
            square,
            orders,
            cadastral_number,
          } = r.data.data
          this.currentItem = {
            number,
            name,
            id,
            sku,
            address,
            coordinates,
            distance_from_mkad,
            square,
            cadastral_number,
            orders,
            region: {
              title: region ? region.data.name : null,
              value: region ? region.data.id : null,
            },
            highway: {
              title: highway ? highway.data.name : null,
              value: highway ? highway.data.id : null,
            },
          }
          this.isLoadingData = false
          this.$store.commit('objects/itemsForDelete', [id])
        })
        .catch(e => {
          this.$router.push({name: 'objects'})
        })
    },
  },
}
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.sidebar-right-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      //img:not(:first-child) {
      //  margin-left: 8px;
      //}

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }

  > .sidebar-right-preloader {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style lang="scss" scoped>
.sidebar-right-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  position: relative;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
      //margin-right: 5px;
      //margin-bottom: 2px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__info-container:not(:first-child) {
    //margin-top: 6px;
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
